    <div class="fondo-item"
        [@eliminar]="eliminar ? 'eliminado' : 'void'"
        (@eliminar.done)="onEliminarDoneEvent($event)"
    >

        <div class="container item-cesta mat-elevation-z3" 
        [@eliminar]="eliminar ? 'eliminado' : 'void'"
        (@eliminar.done)="onEliminarDoneEvent($event)"
        [ngClass]="{'item-erroneo' : error}"
        (click)="seleccionarItem()"
        >
            <div class="row align-items-center">
    
                <div class="col-1 boton-eliminar">
                    <button mat-mini-fab color="warn" (click)="eliminarItem()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
    
                <div class="col-1">
                    <img src="{{item?.curso.imagen_ic}}" class="imagen-curso mat-elevation-z3">
                </div>
                <div class="col-5 nombre-curso">
                    {{item?.curso.nombre}}
                </div>
                <div class="col-2 precio">
                    <span class="tachado precio-sin-descuento" *ngIf="item?.codigo_descuento != null && (item?.cursosAplicables[0] != null || item?.codigo_descuento.general == 1)">{{ item?.curso.precio_seleccionado | currency:'EUR'}}</span>
                    <span class="precio-definitivo" *ngIf="item?.codigo_descuento != null && (item?.cursosAplicables[0] != null || item?.codigo_descuento.general == 1)">{{ precio | currency:'EUR'}}</span>
                    <span class="precio-definitivo" *ngIf="item?.codigo_descuento == null || (item?.cursosAplicables[0] == null && item?.codigo_descuento.general != 1) ">{{ item?.curso.precio_seleccionado | currency:'EUR'}}</span>
                </div>
                <div class="col-2 ">
                    <button mat-raised-button color="primary">
                        <span class="texto-editar first-capitalize">{{'convocatorias' | translate}}</span> <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
    
            </div>
        </div>
    </div>
