import { Modalidad } from './modalidad';
import { TipoModalidad } from 'src/app/enum/tipo-modalidad.enum';
import { AppSettings } from "src/app/app-settings";
import { ICurso } from "src/app/interfaces/i-curso";
import { Convocatoria } from "./convocatoria";

export class Curso {

    public idtrcurscat: number = null;
    public descripcion: string = null;
    public objetivosgenerales: string = null;
    public objetivosespecificos: string = null;
    public indice: string = null;
    public duracion: string = null;
    public imagen: string = null;
    public imagen_or: string = null;
    public imagen_ic: string = null;
    public idacreditacion: string = null;
    public es_nebrija: boolean = null;
    public es_cfc: boolean = null;
    public nombreacreditacion: string = null;
    public logo_acreditacion: string = null;
    public logo_or_acreditacion: string = null;
    public logo_ic_acreditacion: string = null;
    public siglas_acreditacion: string = null;
    public nombre: string = null;
    public nombresec: string = null;
    public acreditacion: string = null;
    public precio_seleccionado: number = null;
    public precioonline: number = null;
    public preciodistancia: number = null;
    public preciootros: number = null;
    public modalidadotros: string = null;
    public horas: number = null;
    public mesesduracion: number = null;
    public convocatoriaspublicas: number = null;
    public nombre_entidad_colaboradora: string = null;
    public siglas_entidad_colaboradora: string = null;
    public logo_entidad_colaboradora: string = null;
    public logo_ic_entidad_colaboradora: string = null;
    public logo_or_entidad_colaboradora: string = null;
    public convocatoria_seleccionada:Convocatoria = null;
    public convocatorias:Convocatoria[] = null;
    public categoria_diploma:string = null;
    public modalidad_seleccionada: Modalidad = null;
    public modalidades: Modalidad[] = [];

    constructor(curso: ICurso = null){

          if(curso !== null){
               this.idtrcurscat                   = curso.idtrcurscat;
               this.descripcion                   = curso.descripcion;
               this.objetivosgenerales            = curso.objetivosgenerales;
               this.objetivosespecificos          = curso.objetivosespecificos;
               this.indice                        = curso.indice;
               this.duracion                      = curso.duracion;
               this.imagen                        = curso.imagen;
               this.imagen_or                     = curso.imagen_or;
               this.imagen_ic                     = curso.imagen_ic;
               this.idacreditacion                = curso.idacreditacion;
               this.es_nebrija                    = curso.es_nebrija;
               this.es_cfc                        = curso.es_cfc;
               this.nombreacreditacion            = curso.nombreacreditacion;
               this.logo_acreditacion             = curso.logo_acreditacion;
               this.siglas_acreditacion           = curso.siglas_acreditacion;
               this.nombre                        = curso.nombre;
               this.nombresec                     = curso.nombresec;
               this.acreditacion                  = curso.acreditacion;
               this.precioonline                  = +curso.precioonline;
               this.preciodistancia               = +curso.preciodistancia;
               this.preciootros                   = +curso.preciootros;
               this.modalidadotros                = curso.modalidadotros;
               this.horas                         = +curso.horas;
               this.mesesduracion                 = +curso.mesesduracion;
               this.convocatoriaspublicas         = +curso.convocatoriaspublicas;
               this.convocatorias                 = curso.convocatorias;
               this.nombre_entidad_colaboradora   = curso.nombre_entidad_colaboradora;
               this.siglas_entidad_colaboradora   = curso.siglas_entidad_colaboradora;
               this.logo_entidad_colaboradora     = curso.logo_entidad_colaboradora;
               this.logo_ic_entidad_colaboradora  = curso.logo_ic_entidad_colaboradora;
               this.logo_or_entidad_colaboradora  = curso.logo_or_entidad_colaboradora;
               this.setLogo_or_acreditacion(curso.logo_or_acreditacion);
               this.setLogo_ic_acreditacion(curso.logo_ic_acreditacion);
               this.convocatoria_seleccionada     = curso.convocatoria_seleccionada;
               this.categoria_diploma             = curso.categoria_diploma;

               if(this.precioonline > 0){
                    let modalidad: Modalidad = new Modalidad(TipoModalidad.ONLINE, 'online');
                    this.modalidades.push(modalidad);
                    this.setModalidadSeleccionada(modalidad);
               }
               if(this.preciodistancia > 0){
                    this.modalidades.push(new Modalidad(TipoModalidad.DISTANCIA, 'distancia'));
               }
               if(this.preciootros > 0){
                    this.modalidades.push(new Modalidad(TipoModalidad.ONLINE, this.modalidadotros));
               }

               if(curso.modalidad_seleccionada != null && curso.modalidad_seleccionada != undefined){
                    this.setModalidadSeleccionada(curso.modalidad_seleccionada);
               }

          }
     
     }


     public setModalidadSeleccionada(modalidad: Modalidad){
          switch(modalidad.id){
               case TipoModalidad.ONLINE:{
                    this.precio_seleccionado = this.precioonline;
                    break;
               }
               case TipoModalidad.DISTANCIA:{
                    this.precio_seleccionado = this.preciodistancia;
                    break;
               }
               case TipoModalidad.OTROS:{
                    this.precio_seleccionado = this.preciootros;
                    break;
               }
          }

          this.modalidad_seleccionada = new Modalidad(modalidad.id, modalidad.nombre);
     }


    /**
     * Setter logo_or_acreditacion
     * @param {string } value
     */
	public setLogo_or_acreditacion(value: string ) {
          let string = new String(value);

          if(string.indexOf(AppSettings.GF_ACRED_IMG_PATH) == -1){
               this.logo_or_acreditacion = AppSettings.GF_ACRED_IMG_PATH + '/' + value;
          }else{
               this.logo_or_acreditacion = value;
          }
	}

    /**
     * Setter logo_ic_acreditacion
     * @param {string } value
     */
	public setLogo_ic_acreditacion(value: string ) {
          let string = new String(value);

          if(string.indexOf(AppSettings.GF_ACRED_IMG_PATH) == -1){
               this.logo_ic_acreditacion = AppSettings.GF_ACRED_IMG_PATH + '/' + value;
          }else{
               this.logo_ic_acreditacion = value;
          }
	}


}
