import { CategoriaSeleccionable } from 'src/app/classes/entity-data-models/categoria-seleccionable';
import { CategoriaCurso } from 'src/app/classes/entity-data-models/categoria-curso';
import { HttpClient } from '@angular/common/http';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { AppSettings } from 'src/app/app-settings';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResponseApi } from '../classes/response-api';
import { WaitingSpinnerService } from '../components/waiting-spinner/service/waiting-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriascursosService {
  private baseURL: string = AppSettings.API_ENDPOINT;
  private id_empresaEnc: string = null;
  private enc: Encriptacion = new Encriptacion();

  private _categorias = new BehaviorSubject(<CategoriaCurso[]> null);
  public categorias = this._categorias.asObservable();

  private _categoria_seleccionada = new BehaviorSubject(<CategoriaCurso> null);
  public categoria_seleccionada = this._categoria_seleccionada.asObservable();

  private _categorias_seleccionables = new BehaviorSubject(<CategoriaSeleccionable[]> null);
  public categorias_seleccionables = this._categorias_seleccionables.asObservable();

  constructor(
    private http: HttpClient,
    private waiting_spinner: WaitingSpinnerService
    ) {
    this.id_empresaEnc = this.enc.encriptar(AppSettings.id_empresa.toString());
  }

  get(){
    let request = {
      id_empresa: this.id_empresaEnc
    };
    return this.http.post(this.baseURL + '/categorias_cursos/find/by/empresa', request);
  }

  findByCatcur(id){
    let request = {
      id: id
    };
    return this.http.post(this.baseURL + '/categorias_cursos/find/by/catcur', request);
  }

  categoriaCurso(id){
    let request = {
      idtrcurscat: id
    };
    return this.http.post(this.baseURL + '/categorias_cursos/find/by/curso', request);
  }


  updateCategorias(): void{
    this.waiting_spinner.activar();
    this.get().subscribe(
      (res: ResponseApi) => {
        this.waiting_spinner.desactivar();
        if(res != null){
          this._categorias.next(res.data.categorias);
        }
      },
      error => this.waiting_spinner.desactivar()
    );
  }
  getSeleccionables(){
    let request = {
      id_empresa: this.id_empresaEnc
    };
    return this.http.post(this.baseURL + '/categorias_seleccionables/find/by/empresa', request);
  }

  updateCategoriasSeleccionables(): void{
    this.getSeleccionables().subscribe(
      (res: ResponseApi) => {
        if(res != null){
          this._categorias_seleccionables.next(res.data.categorias);
        }
      }
    );
  }

  setCategoriaSeleccionada(categoria: CategoriaCurso){
    this._categoria_seleccionada.next(categoria);
  }

  eliminarCategoriaSeleccionada(){
    this._categoria_seleccionada.next(null);
  }
}
