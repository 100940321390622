<div class="container" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <div class="col-md-12 card-info mat-elevation-z8">

            <div class="container">
                <div class="row">
                    <div class="col-md-12 titulo">
                        {{'compra exito' | translate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 cuerpo">
                        <span [innerHTML]="'instrucciones transferencia' | translate"></span> <span class="precio">{{pedido_realizado?.precio | currency: 'EUR'}}</span>
                        <span [innerHTML]="'instrucciones transferencia2' | translate:{id_pedido: pedido_realizado?.id}"></span>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="loggedin" class="col-md-12 cuenta">
                        {{cuenta}} 
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
