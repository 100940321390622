import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { TipoPago } from 'src/app/classes/entity-data-models/tipo-pago';
import { TipoPagoPedido } from 'src/app/enum/tipo-pago-pedido.enum';
import { CestaService } from 'src/app/services/cesta.service';

@Component({
  selector: 'app-item-forma-pago',
  templateUrl: './item-forma-pago.component.html',
  styleUrls: ['./item-forma-pago.component.css']
})
export class ItemFormaPagoComponent implements OnInit {
  @Input() forma_pago: TipoPago = null;
  @Output() forma_pago_seleccionada = new EventEmitter<TipoPago>();

  public esta_seleccionada: boolean;
  public version_str: string;

  constructor(
    private cesta_srv: CestaService
  ) {
    this.esta_seleccionada = false;
    this.version_str = "?v=" + AppSettings.VERSION;
  }

  ngOnInit(): void {
    this.cesta_srv.id_forma_pago.subscribe((val: number) => this.esta_seleccionada = (this.forma_pago.id == val));
  }

  onClick(){
    if(this.estaActiva()){
      this.forma_pago_seleccionada.emit(this.forma_pago);
    }
  }

  estaActiva(): boolean{
    return true;
  }

}
