import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import PBKDF2 from 'crypto-js/pbkdf2';
import SHA512 from 'crypto-js/sha512';
import SHA1 from 'crypto-js/sha1';
import Hex from 'crypto-js/enc-hex';

export class Encriptacion {
    private pass: string = "1254f6tdjktjn45y7ko2bngtnstnypri";
    private iv = "hdsgtestmpekit45"; 
    private salt = "hgtecs549ykjd74gstjpnnbe59dfopre";

    constructor(){

    }

    encriptar(str: string){
        const wordArray = Utf8.parse(str);
        const base64 = Base64.stringify(wordArray);
    
        return base64;
    
    /*
        const encPassword = 'J7K67DVDJ7K67DVDJ7K67DVDJ7K67DVD';
        var iv = "1234567812345678";
        const res = CryptoJS.AES.encrypt(val.trim(), encPassword.trim(), {iv: iv}).toString();
        return res;/* */
      }
    desencriptar(base64: string){
        var words = Base64.parse(base64);
        var textString = Utf8.stringify(words);
        return textString;
    /*
        const encPassword = 'J7K67DVDJ7K67DVDJ7K67DVDJ7K67DVD';
        var iv = "1234567812345678";
        const res = CryptoJS.AES.encrypt(val.trim(), encPassword.trim(), {iv: iv}).toString();
        return res;/* */
    }

    desencriptarToken(token: string){
        var passphrase = this.pass;
        var arr = token.split(":");
        var encrypted = arr[0];
        var salt = this.salt;
        var iv = this.iv;
        var key = PBKDF2(passphrase, salt, {
            hasher: SHA512,
            keySize: 64 / 8,
            iterations: 999
        });
        var decrypted = AES.decrypt(encrypted, key, {
            iv: iv
        });

        return decrypted.toString(Utf8);
    }

    encriptarFirmaTPV(str: string): string{
        var hash = SHA1(str);
        return Hex.stringify(hash);

    }
    
}
