import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppSettings } from '../app-settings';

@Injectable({
    providedIn: 'root'
})
export class AvisosService {
    private baseUrl = AppSettings.API_ENDPOINT;

    private _aviso = new BehaviorSubject(<[]>null);
    public aviso = this._aviso.asObservable();

    constructor(private http: HttpClient) { }

    get() {
        return this.http.get(this.baseUrl + '/avisos/' + AppSettings.id_empresa + '/current');
    }
}
