import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class AlumnosService {
  private baseUrl = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) {}

  insertar(request){
    return this.http.post(this.baseUrl + '/alumno/create', request);
  }

  modificar(request){
    return this.http.post(this.baseUrl + '/alumnos/update', request);
  }

  obtenerCuentasBancarias(request){
    return this.http.post(this.baseUrl + '/alumnos/obtener/cuentas', request);
  }

  modificarCuentaBancaria(request){
    return this.http.post(this.baseUrl + '/alumnos/update/cuenta', request);
  }

  insertarCuentaBancaria(request){
    return this.http.post(this.baseUrl + '/alumnos/create/cuenta', request);
  }

}
