<div class="anadir-cesta">
    <app-boton-anadir-cesta *ngIf="!existeItemEnCesta()" [curso]="cursoVista" [codigo_descuento]="descuento"></app-boton-anadir-cesta>
</div>
<div class="esta-en-cesta mat-elevation-z3" *ngIf="existeItemEnCesta()">
    {{'esta en cesta' | translate}}
</div>
<div class="mat-elevation-z4" [ngClass]="{'curso-seleccionado' : existeItemEnCesta() , 'card-curso' : !existeItemEnCesta()}" (click)="redireccionarFichaCurso()">

    <div class="card-content">
        <div class="row justify-content-center row-imagen">
            <div class="imagen mat-elevation-z8" style="background-image: url('{{cursoVista.imagen}}');"></div>
        </div>

        <div class="row justify-content-center">
            <div class="divider"></div>
        </div>

        <div class="row">
            <div class="col nombre-curso">
                <span class="first-capitalize">{{cursoVista.nombre}}</span>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="divider"></div>
        </div>

    </div>
    <div class="pie">
        <div class="precio">
            <span class="tachado precio-sin-descuento" *ngIf="descuento != null">{{ cursoVista.precioonline | currency:'EUR'}}</span>
            <span *ngIf="descuento != null">{{ precio | currency:'EUR'}}</span>
            <span *ngIf="descuento == null">{{ cursoVista.precioonline | currency:'EUR'}}</span>
        </div>
        <div class="card-action">
            <div class="row align-items-center">
                <div class="col-md-4">
                    <div class="contenedor-logo-acreditacion">
                        <img class="logo-acreditacion" src="{{cursoVista.logo_acreditacion}}" alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <ng-container *ngIf="!cursoVista.es_nebrija">{{cursoVista.acreditacion}}</ng-container>
                    <ng-container *ngIf="cursoVista.es_nebrija">{{cursoVista.nombreacreditacion}}</ng-container>
                    <hr  *ngIf="cursoVista.nombreacreditacion=='Universidad Católica de Ávila'">
                    <ng-container *ngIf="cursoVista.nombreacreditacion=='Universidad Católica de Ávila'"> Acreditado a Instituto BAC Formación</ng-container>

                </div>
            </div>
        </div>
    </div>
</div>
