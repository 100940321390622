import { Step } from 'src/app/enum/step.enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/app-settings';
// import { SnotifyService } from 'ng-snotify';
import { Router } from '@angular/router';
import { TokenService } from '../auth/token/token.service';
import { AuthService } from '../auth/auth.service';
import { AlumnosService } from '../alumnos.service';
import { TranslateService } from '@ngx-translate/core';
import { WaitingSpinnerService } from 'src/app/components/waiting-spinner/service/waiting-spinner.service';
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  private baseUrl = AppSettings.API_ENDPOINT;



  private formDP = {
    nombre: null,
    apellidos: null,
    apellido1: null,
    apellido2: null,
    tipo_documento: null,
    dni: null,
    copia_dni: null,
    sexo: null,
    nacimiento: null,
    nac_vista: null,
    nacionalidad: null,
    profesion: null,
    telefono1: null,
    telefono2: null,
    email: null,
    direccion1: null,
    municipio1: null,
    provincia1: null,
    codigopostal1: null,
    id_empresa: AppSettings.id_empresa,
    contrasena: null,
    afiliacionfile: null,
};

  private _step                 = new BehaviorSubject(null);
  private _index                = new BehaviorSubject(null);
  private _firstSubmit          = new BehaviorSubject(null);
  private _formDatosPersonales  = new BehaviorSubject(null);
  private _formContrasena       = new BehaviorSubject(null);
  private readonly notifier: NotifierService;

  step                = this._step.asObservable();
  index               = this._index.asObservable();
  firstSubmit         = this._firstSubmit.asObservable(); //Establece la bandera para poder mostrar errores de validación en los formularios
  formDatosPersonales = this._formDatosPersonales.asObservable();
  formContrasena      = this._formContrasena.asObservable();

  constructor(
    private http: HttpClient,
    // private sNotify: SnotifyService,
    private router: Router,
    private Token: TokenService,
    private Auth: AuthService,
    private alumnosServ: AlumnosService,
    private translateServ: TranslateService,
    private waitingSpinner: WaitingSpinnerService,
    private notifierService: NotifierService

  ) {
    this.updateDatosPersonales(null);
    this.setFirstSubmit(false);
  this.notifier = notifierService;
  }
  enviarDatos(){
    if(this.formularioOK()){
      let formData: FormData = new FormData();
      //Datos Personales
      formData.append('file', this._formDatosPersonales.value.afiliacionfile);
      formData.append('datos_personales', JSON.stringify(this._formDatosPersonales.value));
      this.waitingSpinner.activar();
      this.alumnosServ.insertar(formData).subscribe(
        data  => this.handleEnvioOK(data),
        error => this.handleEnvioKO(error)
      );/**/
    }
    /* */
  }

  siguiente(){
    this._step.next(Step.Siguiente);
  }

  anterior(){
    this._step.next(Step.Anterior);
  }

  irIndice(indice: number){
    this._index.next(indice);
  }

  setFirstSubmit(value: boolean){
    this._firstSubmit.next(value);
  }

  updateDatosPersonales(form: UntypedFormGroup){
    if(form != null){
      let data = form.value;

      this.formDP.nombre          = data.nombre;
      this.formDP.apellidos       = data.apellido1 + ' ' + data.apellido2;
      this.formDP.apellido1       = data.apellido1;
      this.formDP.apellido2       = data.apellido2;
      this.formDP.tipo_documento  = data.tipoDocumento;
      this.formDP.dni             = data.dni;
      this.formDP.sexo            = data.sexo;
      this.formDP.nacimiento      = data.nacimiento.formato_mysql;
      this.formDP.nac_vista       = data.nacimiento.formato_vista;
      this.formDP.nacionalidad    = data.nacionalidad;
      this.formDP.telefono1       = data.telefono1;
      this.formDP.telefono2       = data.telefono2;
      this.formDP.email           = data.email;
      this.formDP.direccion1      = data.direccion1;
      this.formDP.municipio1      = data.municipio1;
      this.formDP.provincia1      = data.provincia1;
      this.formDP.codigopostal1   = data.codigopostal1;
      this.formDP.afiliacionfile = data.afiliacionfile;

      this._formDatosPersonales.next(this.formDP);
    }
  }
  updateContrasena(form: UntypedFormGroup){
    this.formDP.contrasena = form.controls.password.value;
    this._formDatosPersonales.next(this.formDP);
    this._formContrasena.next(form);
  }

  irPrimerIndiceConErrores(){
    this.irIndice(this.primerIndiceConErrores());
  }

 private primerIndiceConErrores(): number{
    if(this._formDatosPersonales.value == null){
      return 0;
    }
    if(this._formContrasena.value == null){
      return 1;
    }

    return 2;
 }

  private handleEnvioOK(data){
    this.waitingSpinner.desactivar();
    let token = data.data.api_token;
    if(token != null){
      this.translateServ.get('registrado exito').subscribe((msg:string) => {
        this.notifier.notify('success', msg)
        // this.sNotify.success(msg, AppSettings.sNotifyCommonConf);
      });
      this.Token.handle(token);
      this.Auth.changeAuthStatus(true);
      this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
    }
  }
  private handleEnvioKO(error){
    this.waitingSpinner.desactivar();
    this.notifier.notify('error', error.error.message)

    // this.sNotify.error(error.error.message, AppSettings.sNotifyCommonConf);
  }

  formularioOK(){
    if(this._formDatosPersonales.value != null && this._formContrasena.value != null){
      return true;
    }
    return false;
  }
}
