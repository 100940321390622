<div class="container">
  <div class="row">
    <div class="col-md-12">
      <button mat-button color="primary" (click)="toggleBuscadorDescuentos()">
        {{'tengo codigo descuento' | translate}} 
        <mat-icon *ngIf="!esta_abierto_buscador_descuentos">expand_more</mat-icon> 
        <mat-icon *ngIf="esta_abierto_buscador_descuentos">expand_less</mat-icon> 
      </button>
    </div>
  </div>
  <div class="row" *ngIf="esta_abierto_buscador_descuentos">
    <div class="col-md-12">
      <app-buscador-descuentos style="width: 100%;"></app-buscador-descuentos>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="tab_selected" (selectedTabChange)="onSelectedTabChange($event)">
            <mat-tab label="{{'cursos' | translate}}">
              <ng-template matTabContent>
                <div class="container-cursos">
                    <div class="cabecera-item" *ngFor="let item of cesta_compra">
                      <app-item-cesta [item]="item" [error]="esErroneo(item)" [item_seleccionado]="itemSeleccionado" (seleccionar_item)="seleccionarItem(item)" (eliminando_item)="setEliminandoItem($event)"></app-item-cesta>
                    </div>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab label="{{(itemSeleccionado == null) ? '' : i18n_detalles}}" [disabled]="itemSeleccionado == null">
              <ng-template matTabContent>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <button mat-icon-button (click)="tab_selected = 0">
                        <mat-icon>arrow_back</mat-icon>
<!--                        {{'volver' | translate}}-->
                      </button>
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let item of cesta_compra">
                  <app-detalles-item-cesta *ngIf="item.curso.idtrcurscat == itemSeleccionado?.curso.idtrcurscat" [item]="item" (item_modificado)="actualizarItemCesta($event)"></app-detalles-item-cesta>
                </ng-container>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
    </div>
  </div>
</div>

