<div class="container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
            <mat-form-field class="input-text">
                <mat-label><span class="first-capitalize">{{'contrasena' | translate}}</span></mat-label>
                <input matInput type="password" formControlName="password" class="input-login">
            </mat-form-field>
            <mat-error *ngIf="firstSubmit && form.controls.password.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
            <mat-form-field class="input-text">
                <mat-label><span class="first-capitalize">{{'repetir contrasena' | translate}}</span></mat-label>
                <input matInput type="password" formControlName="password_confirm" class="input-login">
            </mat-form-field>
            <mat-error *ngIf="firstSubmit && form.errors?.required" [innerHTML]="'error contrasena confirmation' | translate"></mat-error>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-12">
        <button mat-raised-button (click)="enviar()" color="primary-aetesys" class="first-capitalize boton-full-width" [disabled]="form.status != 'VALID'">{{'aceptar contraseña' | translate}}</button>
      </div>
    </div>
  </form>
</div>
