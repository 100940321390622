<mat-card matRipple [matRippleColor]="'#4e7c2960'" [class.card-seleccionada]="esta_seleccionada" [class.card-forma-pago-activa]="estaActiva()" class="card-forma-pago-item mat-elevation-z12" ng (click)="onClick()">
    <mat-card-header>
      <div mat-card-avatar style="background-image: url({{forma_pago?.imagen}}); background-size: cover;"></div>
      <mat-card-title>{{forma_pago?.nombre}}</mat-card-title>
      <mat-card-subtitle>{{forma_pago?.descripcion}}</mat-card-subtitle>
    </mat-card-header>

    <div class="imagen-tipo-pago-container mat-elevation-z3">
        <img mat-card-image class="imagen-tipo-pago" src="{{forma_pago?.imagen}}{{version_str}}">
    </div>
    
    <mat-card-content>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <mat-icon *ngIf="esta_seleccionada" class="radio-button-icon">radio_button_checked</mat-icon>
            <mat-icon *ngIf="!esta_seleccionada" class="radio-button-icon">radio_button_unchecked</mat-icon>
          </div>
        </div>
      </div>
    </mat-card-content>
    
  </mat-card>