import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { CategoriaCurso } from 'src/app/classes/entity-data-models/categoria-curso';

@Component({
  selector: 'app-card-categoria',
  templateUrl: './card-categoria.component.html',
  styleUrls: ['./card-categoria.component.css']
})
export class CardCategoriaComponent implements OnInit {
  @Input() categoria: CategoriaCurso = null;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public redireccionarCurso(){
    this.router.navigateByUrl('cursos/' + this.categoria.trcatcur + '/' + this.categoria.nombreesc);
  }

}
