<div class="container">
    <div class="row">
        <div class="col-md-12">
            <mat-card class="card-background mat-elevation-z18">

                <mat-card-header class="card-header">
                    <mat-card-title><h3>{{curso?.nombre}}</h3></mat-card-title>
                </mat-card-header>
                <mat-card-content class="card-content">
                    <div class="container">
                        <div class="row" *ngIf="curso != null && curso.modalidades.length > 1">
                            <div class="col-md-6">
                                <label class="radio-group-horizontal"><span class="first-capitalize">{{'modalidad' | translate}}</span></label>
                                <mat-radio-group [(ngModel)]="id_modalidad_seleccionada" class="radio-group-horizontal">
                                <mat-radio-button (click)="cambiarModalidad(modalidad.id)" [disabled]="existeCurso()" *ngFor="let modalidad of curso.modalidades" [value]="modalidad.id" class="radio-button">
                                    <span class="first-capitalize">{{modalidad.nombre}}</span>
                                </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row fila-1">

                            <div class="col-md-6 col-fila-1">
                                <div class="tag-precio">
                                    <span *ngIf="codigo_descuento != null" class="tachado precio-sin-descuento">{{ curso?.precio_seleccionado | currency:'EUR'}}</span>
                                    <span *ngIf="codigo_descuento != null">{{ precio | currency:'EUR'}}</span>
                                    <span *ngIf="codigo_descuento == null">{{ curso?.precio_seleccionado | currency:'EUR'}}</span>
                                </div>
                                <img class="imagen-curso mat-elevation-z6" src="{{curso?.imagen_or}}" alt="">
                            </div>
                            <div class="col-md-6 col-fila-1">
                                <div class="panel-acreditacion mat-elevation-z6">
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <img src="{{curso?.logo_acreditacion}}" class="logo-acreditacion" alt="">
                                            </div>
                                            <div class="col-md-7">

                                               <span class="nombre-acreditacion" >{{curso?.nombreacreditacion}}  </span>
                                               <hr *ngIf="curso?.nombreacreditacion == 'Universidad Católica de Ávila'">
                                               <span class="nombre-acreditacion" *ngIf="curso?.nombreacreditacion == 'Universidad Católica de Ávila'">Acreditado a  Instituto BAC Formación</span>

                                            </div>
                                        </div>
                                        <div class="row fila-acreditacion" *ngIf="curso?.es_nebrija">
                                            <div class="col-md-12 first-capitalize">
                                                <strong>{{'acreditacion' | translate}}</strong> : {{curso?.acreditacion}}
                                            </div>
                                        </div>
                                        <div class="row fila-acreditacion">
                                            <div class="col-md-12 first-capitalize">
                                                <strong>{{'duracion' | translate}}</strong> : {{curso?.horas}} {{'horas' | translate}}
                                            </div>
                                        </div>
                                        <div class="row fila-acreditacion">
                                            <div class="col-md-12 first-capitalize">
                                                <strong>{{'colabora' | translate}}</strong> : {{curso?.nombre_entidad_colaboradora}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="curso?.convocatorias?.length > 0">
                            <div class="col-md-12">
                                <button mat-raised-button color="primary" class="boton-anadir-cesta boton-full-width mat-elevation-z6" (click)="anadirCarrito()" [disabled]="existeCurso()"><span class="first-capitalize">{{'anadir cesta' | translate}}</span></button>
                            </div>
                        </div>

                        <div class="row fila-2">
                            <div class="col-md-12">
                                <div class="panel-info-tabs mat-elevation-z6">
                                    <mat-tab-group animationDuration="1000ms">
                                        <mat-tab label="{{'objetivo general' | translate}}">
                                            <div class="texto-tab" [innerHTML]="curso?.objetivosgenerales"></div>
                                        </mat-tab>
                                        <mat-tab label="{{'objetivo especifico' | translate}}">
                                            <div class="texto-tab" [innerHTML]="curso?.objetivosespecificos"></div>
                                        </mat-tab>
                                        <mat-tab label="{{'indice curso' | translate}}">
                                            <div class="texto-tab" [innerHTML]="curso?.indice"></div>
                                        </mat-tab>
                                        <mat-tab label="{{'convocatorias' | translate}}" *ngIf="curso?.convocatorias != null" [disabled]="existeCurso()">
                                            <div class="texto-tab">
                                                <div class="container-fluid">
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <div>{{'seleccione convocatoria' | translate}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3 p-2 mat-elevation-z4 fila-convocatoria" [ngClass]="{'convocatoria-seleccionada': curso?.convocatoria_seleccionada == convocatoria}" *ngFor="let convocatoria of curso?.convocatorias" (click)="seleccionarConvocatoria(convocatoria)">
                                                        <div class="col-md-4 p-1">
                                                            <div class="">
                                                                {{formatearFecha(convocatoria.fechainicio)}} - {{formatearFecha(convocatoria.fechafin)}}
                                                            </div>
                                                        </div>
<!--                                                        <div class="col-md-4 p-1" *ngIf="!curso?.es_nebrija">-->
<!--                                                            <div class="">-->
<!--                                                                <span class="label-info-convocatoria">{{'creditos' | translate}}:</span> {{convocatoria.creditosconvocatoria}}-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                        <div class="col-md-4 p-1" *ngIf="!curso?.es_nebrija">-->
<!--                                                            <div class="">-->
<!--                                                                <span class="label-info-convocatoria">{{'codigo' | translate}}:</span> {{convocatoria.codigoconvocatoria}}-->
<!--                                                            </div>-->
<!--                                                        </div>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>
