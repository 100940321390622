import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemMenuCurso } from 'src/app/classes/entity-data-models/item-menu-curso';

@Injectable({
  providedIn: 'root'
})
export class MenuCursosService {

  private _menu = new BehaviorSubject(<ItemMenuCurso[]> null);
  public menu = this._menu.asObservable();

  constructor() { }

  push(item: ItemMenuCurso){
    let menu: ItemMenuCurso[] = this._menu.value;
    if(menu.filter((m: ItemMenuCurso) => m.link == item.link).length == 0){
      menu.push(item);
      this._menu.next(menu);
    }
  }

  pop(){
    let menu: ItemMenuCurso[] = this._menu.value;
    menu.pop();
    this._menu.next(menu);
  }

  truncate(item: ItemMenuCurso){
    let menu: ItemMenuCurso[]         = this._menu.value;
    let indice: number                = menu.indexOf(item);
    let menu_slice: ItemMenuCurso[]   = menu.slice(0, indice);
        
    this._menu.next(menu_slice);   
  }

  delete(){
    let menu: ItemMenuCurso[] = [];

    this._menu.next(menu);
  }
  
}
