<footer class="footer">
  <div class="row p-3 m-0" style="text-align:-webkit-center">
    <div  class="col-md-6 text-center text-white ">
      <label class="titulo_seleccion"> Contacto:</label>
      <div class="">
        <div class=" text-white text-center p-3" >
          <div class=" cajascontacto">
            <p class="fw-bold  texto-foot ">Email</p>
            <a class="fw-bold text-white texto-foot" href="mailto:info@formacionegs.com">info@formacionegs.com</a>
            <p class=" texto-foot-small">(Para evitar esperas, recomendamos esta opción)<p>
          </div>
        </div>
        <div class=" text-white text-center p-3" >
          <div class= "cajascontacto">
            <p class="fw-bold   texto-foot  ">  Teléfono</p>
            <p class="fw-bold  texto-foot ">886 208 092</p>
            <p class=" texto-foot-small ">De Lunes a Viernes de 10:00 a 14:00</p>
          </div>

        </div>

      </div>
    </div>

    <div  class="col-md-6 text-center text-white " >
      <label class="titulo_seleccion">Ubicación: </label>
      <div class=" text-white text-center " >
        <div class="">
          <p class="p-2 texto-foot"> P.º de Colón, 15, Local I, 36002 Pontevedra</p>
          <a target="_blank" href="https://www.google.com/maps?ll=42.431219,-8.651674&amp;z=14&amp;t=m&amp;hl=es-ES&amp;gl=ES&amp;mapclient=embed&amp;cid=7908511357295994269" rel="noreferrer"><img src="https://www.formacionegs.com/img/mapagoogle.jpg" alt="mapa google" title="mapa google" style="width: 57%"></a>
        </div>
      </div>
    </div>

  </div>
  <div class="bg-black text-center">
    <div class="container row m-auto pt-2 pb-2 text-white">
      <div class="col-md-12 text-start">
        Copyright @ 2023 formacionegs.com - Todos los derechos reservados
      </div>
      <div class="col-md-12 text-end">
        <a href="/aviso_legal" target="_blank" class="text-white text-decoration-none">Aviso Legal</a> -
        <a href="/politica_privacidad" target="_blank" class="text-white text-decoration-none">Política de Privacidad</a> -
        <a href="/faqs" target="_blank" class="text-white text-decoration-none">Preguntas Frecuentes</a> -
        <a href="/politica_cookies" target="_blank" class="text-white text-decoration-none">Política de Cookies</a>
      </div>
    </div>
  </div>

</footer>

<!--<footer class="footer">-->
<!--    <div class="container-fluid">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-md-3">-->
<!--                <div class="container">-->
<!--                    <div class="row align-items-center">-->
<!--                        <div class="col-md-6" style="text-align: left;">-->
<!--                            {{'colaboracion con' | translate}}:-->
<!--                        </div>-->
<!--                        <div class="col-md-6 logo-bac">-->
<!--                            <img src="{{url_logo_BAC}}" alt="">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                  -->
<!--            </div>-->
<!--            <div class="col-md-6">-->
<!--                <div class="row justify-content-center">-->
<!--                    <div class="col-md-6">-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                                <a [routerLink]="['aviso-legal']">-->
<!--                                    {{'aviso legal' | translate}}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                                <a [routerLink]="['condiciones-generales']">-->
<!--                                    {{'condiciones generales' | translate}}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->

<!--                                <a href="javascript:;" (click)="abrirContacto()" class="first-capitalize">-->
<!--                                    {{'contacto' | translate}}-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>   -->
<!--</footer>-->

