import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private baseUrl = AppSettings.API_ENDPOINT;

  private _isValid = new BehaviorSubject(<boolean>null);
  isValidToken = this._isValid.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    //this.checkToken();
  }

  handle(token) {
      this.set(token);
  }

  set(token) {
      localStorage.setItem('token', token);
  }
  get() {
     return localStorage.getItem('token');
  }
  remove() {
      localStorage.removeItem('token');
  }
  checkToken() {
      let request = {
        token: this.get(),
        admin: false
      };
      return this.http.post(this.baseUrl + '/auth/check/token', request);
  }

  loggedIn() {
     return this._isValid.value;
  }
}
