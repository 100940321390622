<div class="fondo" *ngIf="activado">

    <div class="row justify-content-center align-items-center" style="height: 100%;">
        <div class="col-md-2" style="margin: 40px;">
            <mat-card class="spinner-card mat-elevation-z14">
                <mat-card-header>
                  <mat-card-title>{{titulo}}</mat-card-title>
                  <mat-card-subtitle>{{subtitulo}}</mat-card-subtitle>
                </mat-card-header>
                <div class="row justify-content-center" style="width: 100%;" *ngIf="mostrarSpinner">
                    <div class="col-5">
                        <mat-spinner [color]="color"></mat-spinner>
                    </div>
                    
                </div>
                <mat-card-actions>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

</div>