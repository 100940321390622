import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { Pedido } from 'src/app/classes/entity-data-models/pedido';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-pedido-realizado',
  templateUrl: './pedido-realizado.component.html',
  styleUrls: ['./pedido-realizado.component.css']
})
export class PedidoRealizadoComponent implements OnInit {

  public cuenta: string = 'ES56 0182 0638 9202 0159 9577 (BBVA)';
  public pedido_realizado: Pedido = null;
  public loggedin: boolean = null;

  constructor(
    private pedidosSrv: PedidosService,
    private Auth: AuthService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.pedidosSrv.pedido_insertado.subscribe((pedido: Pedido) => {

      this.pedido_realizado = pedido;
      if(this.pedido_realizado == null){
        this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
      }
    
    });
    this.Auth.authStatus.subscribe((loggedin: boolean) => {
      this.loggedin = loggedin;
      if(loggedin == false){
        this.router.navigateByUrl(AppSettings.LOGIN_ROUTE);
      }
    });

  }

}
