import { DateFormat } from 'src/app/classes/date-format';
import { Convocatoria } from 'src/app/classes/entity-data-models/convocatoria';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { CategoriascursosService } from 'src/app/services/categoriascursos.service';
import { CategoriaSeleccionable } from 'src/app/classes/entity-data-models/categoria-seleccionable';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Modalidad } from 'src/app/classes/entity-data-models/modalidad';

@Component({
  selector: 'app-detalles-item-cesta',
  templateUrl: './detalles-item-cesta.component.html',
  styleUrls: ['./detalles-item-cesta.component.css']
})
export class DetallesItemCestaComponent implements OnInit, OnChanges {
  @Input() item: ItemCesta = null;
  @Output() item_modificado = new EventEmitter<ItemCesta>();

  public convocatorias: Convocatoria[] = [];
  public categorias_seleccionables: string[] = [];

  public filtered_categorias: Observable<string[]>;


  public form: UntypedFormGroup = new UntypedFormGroup({
    id_modalidad : new UntypedFormControl(<number>null, [Validators.required]),
    convocatoria : new UntypedFormControl(<string>null, [Validators.required]),
    categoria_diploma : new UntypedFormControl({ value: <string>null}, [Validators.required]),
  });

  constructor(
    private categoriasSrv: CategoriascursosService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.item != null){
      let convStr: string;
      if(this.item.curso.convocatoria_seleccionada == undefined || this.item.curso.convocatoria_seleccionada == null){
        convStr = '';
      }else{
        convStr = this.item.curso.convocatoria_seleccionada?.fechainicio.toString() + this.item.curso.convocatoria_seleccionada?.fechafin.toString();
      }

      let catDiplo: string = (this.item.curso.categoria_diploma == undefined || this.item.curso.categoria_diploma == null) ? '' : this.item.curso.categoria_diploma;
      let id_modalidad: number = (this.item.curso.modalidad_seleccionada == undefined || this.item.curso.modalidad_seleccionada == null) ? null : this.item.curso.modalidad_seleccionada.id;

      this.form.controls.id_modalidad.setValue(id_modalidad);
      this.form.controls.convocatoria.setValue(convStr);
      this.form.controls.categoria_diploma.setValue(catDiplo);
    }
  }

  ngOnInit(): void {
    this.convocatorias = this.item.curso.convocatorias;

    this.categoriasSrv.categorias_seleccionables.subscribe((res: CategoriaSeleccionable[]) => {
        if(res != null){
          res.forEach((item: CategoriaSeleccionable) =>{
            this.categorias_seleccionables.push(item.nombre);
          });
        }
    });

    this.filtered_categorias = this.form.controls.categoria_diploma.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  public formatearFecha(fecha: string): string{
    let fecha_split = fecha.split(/[- :]/);
    let date = new Date(parseInt(fecha_split[0]), parseInt(fecha_split[1]) - 1, parseInt(fecha_split[2]));
    let format_date = new Intl.DateTimeFormat('es-ES').format(date);
    //console.log(format_date);
    return format_date;
  }

  public submit(){
    this._modificarItemCesta();
  }


  private _filter(value: string): string[] {
    let filterValue = value.toLowerCase();

    return this.categorias_seleccionables.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _modificarItemCesta(){

    let modalidad: Modalidad = this.item.curso.modalidades.find((mod: Modalidad) => mod.id == this.form.controls.id_modalidad.value);
    let convocatoria: Convocatoria = this.convocatorias.find((conv: Convocatoria) => (conv.fechainicio + conv.fechafin).toString() == this.form.controls.convocatoria.value);

    this.item.curso.setModalidadSeleccionada(modalidad);
    this.item.curso.convocatoria_seleccionada  = convocatoria;
    this.item.curso.categoria_diploma = this.form.controls.categoria_diploma.value;

    this.item_modificado.emit(this.item);

  }

}
