import { DNIValidator } from 'src/app/classes/validators/dnivalidator';
import { Alumno } from 'src/app/classes/entity-data-models/alumno';
import { ErrorService } from 'src/app/services/error/error.service';
import { AlumnosService } from 'src/app/services/alumnos.service';
// import { SnotifyService } from 'ng-snotify';
// import {NotifierService} from "angular-notifier";
import { Status } from 'src/app/enum/form/status.enum';
import { TipoDocumento } from 'src/app/enum/form/tipo-documento.enum';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { StepperService } from 'src/app/services/formulario_registro/stepper.service';
import { NacionalidadesService } from 'src/app/services/nacionalidades.service';


import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AppSettings } from 'src/app/app-settings';
import { CommonFunctions } from 'src/app/classes/common-functions';
import { TokenService } from 'src/app/services/auth/token/token.service';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { ProfileService } from 'src/app/services/auth/profile/profile.service';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-paso1',
  templateUrl: './paso1.component.html',
  styleUrls: ['./paso1.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class Paso1Component implements OnInit, OnChanges {

  public nacionalidades = [];
  public tiposDocumento = [
    {id: TipoDocumento.Pasaporte, nombre: "Pasaporte"},
    {id: TipoDocumento.NIE, nombre: "NIE"},
    {id: TipoDocumento.Otro, nombre: "Otro"}
  ];
  public nacionalidadDefault:string = 'ESPAÑOLA';
  @Input() alumno: Alumno = new Alumno();

  private readonly notifier: NotifierService;
  public firstSubmit:boolean = false;
  public isValidForm:boolean = false;

  private errorMSG: string = "Tiene errores en el formulario";

  private afiliacionfile: File | null;

  public updateMode:boolean = false;
  public fechaNacSel = {
    formato_mysql: null,
    formato_vista: null
  };

  public form: UntypedFormGroup = new UntypedFormGroup({
    nombre : new UntypedFormControl('',[
      Validators.required
    ]),
    apellido1 : new UntypedFormControl('',[
      Validators.required
    ]),
    apellido2 : new UntypedFormControl(''),
    nacionalidad : new UntypedFormControl('ESPAÑOLA',[
      Validators.required
    ]),
    sexo : new UntypedFormControl('',[
      Validators.required
    ]),
    nacimiento : new UntypedFormControl('',[
      Validators.required
    ]),
    dni : new UntypedFormControl('',[
      Validators.required,
      DNIValidator.DNIformat()
    ]),
    tipoDocumento : new UntypedFormControl(''),
    direccion1 : new UntypedFormControl('',[
      Validators.required
    ]),
    municipio1 : new UntypedFormControl('',[
      Validators.required
    ]),
    provincia1 : new UntypedFormControl('',[
      Validators.required
    ]),
    codigopostal1 : new UntypedFormControl('',[
      Validators.required,
      
    ]),
    telefono1 : new UntypedFormControl('',[
      Validators.required
    ]),
    telefono2 : new UntypedFormControl(''),
    email : new UntypedFormControl('',[
      Validators.required,
      Validators.email
    ]),
    afiliacionfile:new UntypedFormControl('',[
      Validators.required
    ])
  });


  /* */
  constructor(
    private stepperServ: StepperService,
    private nacionalidadeSrv: NacionalidadesService,
    // private sNotify: SnotifyService,
    private Token: TokenService,
    private alumnosService: AlumnosService,
    private ErrorSrv: ErrorService,
    private profile: ProfileService,
  private notifierService: NotifierService
){
  this.notifier = notifierService;
}
  ngOnChanges(changes: SimpleChanges): void {
    if(this.alumno?.id != null){

      this.fechaNacSel = {
        formato_mysql: this.alumno.nacimiento,
        formato_vista: null
      };

      
      this.form.controls.nombre.setValue(this.alumno.nombre);
      this.form.controls.apellido1.setValue(this.alumno.apellido1);
      this.form.controls.apellido2.setValue(this.alumno.apellido2);
      this.form.controls.sexo.setValue(CommonFunctions.procesarSexo(this.alumno.sexo));
      this.form.controls.nacionalidad.setValue(this.alumno.nacionalidad);
      this.form.controls.nacimiento.setValue(this.fechaNacSel);
      this.form.controls.dni.setValue(this.alumno.dni);
      this.form.controls.tipoDocumento.setValue('');
      this.form.controls.direccion1.setValue(this.alumno.direccion1);
      this.form.controls.municipio1.setValue(this.alumno.municipio1);
      this.form.controls.provincia1.setValue(this.alumno.provincia1);
      this.form.controls.codigopostal1.setValue(this.alumno.codigopostal1);
      this.form.controls.telefono1.setValue(this.alumno.telefono1);
      this.form.controls.telefono2.setValue(this.alumno.telefono2);
      this.form.controls.email.setValue(this.alumno.email);
      this.form.controls.afiliacionfile.setValue(this.alumno.afiliacionfile);


      this.updateMode = true;
    }else{
      this.updateMode = false;
    }
  }

  ngOnInit(): void {
    this.nacionalidadeSrv.nacionalidades.subscribe(data => this.nacionalidades = data);
    this.stepperServ.firstSubmit.subscribe(data => this.firstSubmit = data);
  }

  
  onSubmit(){
  }

  submit(){
    this.firstSubmit = true;
    if(this.form.status === Status.VALID){
      this.stepperServ.updateDatosPersonales(this.form);
      this.stepperServ.irPrimerIndiceConErrores();
    }else if(this.form.status === Status.INVALID){
      this.notifier.notify('error', this.errorMSG);
      // this.sNotify.error(this.errorMSG, AppSettings.sNotifyCommonConf);
    }
  }

  modificar(){
    let datos_alumno: Alumno = new Alumno();
    let enc = new Encriptacion();

    if(this.form != null){
      let data = this.form.value;

      datos_alumno.nombre        = data.nombre;
      datos_alumno.apellidos     = data.apellido1 + ' ' + data.apellido2;
      datos_alumno.apellido1     = data.apellido1;
      datos_alumno.apellido2     = data.apellido2;
      datos_alumno.dni           = data.dni;
      datos_alumno.sexo          = data.sexo;
      datos_alumno.nacimiento    = data.nacimiento.formato_mysql;
      datos_alumno.nacionalidad  = data.nacionalidad;
      datos_alumno.telefono1     = data.telefono1;
      datos_alumno.telefono2     = data.telefono2;
      datos_alumno.email         = data.email;
      datos_alumno.direccion1    = data.direccion1;
      datos_alumno.municipio1    = data.municipio1;
      datos_alumno.provincia1    = data.provincia1;
      datos_alumno.codigopostal1 = data.codigopostal1;
      datos_alumno.afiliacionfile = data.afiliacionfile;
      
    }

    let request = {
      token: this.Token.get(),
      datos_alumno: datos_alumno
    };
    
    this.alumnosService.modificar(request).subscribe(
      data => this.handleModificado(data),
      error => this.ErrorSrv.commonHandler(error)
    );
  }

  addFecha(fecha){
    this.fechaNacSel = fecha;
    this.form.controls.nacimiento.setValue(this.fechaNacSel);
  }

  onFileSelected(event: any) {
    let afiliacionfile: File;
    afiliacionfile = event.target.files[0] || null;
    this.form.value.afiliacionfile=afiliacionfile;
  }

  onChangeNacionalidad(){
    if(this.form.controls.nacionalidad.value == 'ESPAÑOLA'){
      this.form.controls.dni.setValidators([
        Validators.required,
        DNIValidator.DNIformat()
      ]);
      this.form.controls.tipoDocumento.setValue('');
    }else{
      this.form.controls.dni.setValidators([
        Validators.required
      ]);
      this.form.controls.tipoDocumento.setValue(TipoDocumento.Pasaporte);
    }
  }

  onChangeTipoDocumento(){
    switch(this.form.controls.tipoDocumento.value){
      case TipoDocumento.NIE:{
        this.form.controls.dni.setValidators([
          Validators.required,
          DNIValidator.NIEformat()
        ]);
        break;
      }
      case TipoDocumento.Pasaporte:{
        this.form.controls.dni.setValidators([
          Validators.required
        ]);
        break;
      }
      case TipoDocumento.Otro:{
        this.form.controls.dni.setValidators([
          Validators.required
        ]);
        break;
      }
    }
  }

  public keypressDNI(event: any) {
      let value: string = this.form.controls.dni.value;
      if(value.length == 9){
        event.preventDefault();
      }
      CommonFunctions.typeAlphanumericOnly(event);
  }

  private handleModificado(data){

    if(data.status == 200){
      this.profile.updateMe();
      this.notifier.notify('success', 'Datos personales modificados con éxito');
      // this.sNotify.success('Datos personales modificados con éxito', AppSettings.sNotifyCommonConf);
    }
  }
}
