import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  private _abierto = new BehaviorSubject(<boolean> false);

  public abierto = this._abierto.asObservable();

  constructor() { }

  abrir(){
    this._abierto.next(true);
  }

  cerrar(){
    this._abierto.next(false);
  }

  toggle(){
    this._abierto.next(!this._abierto.value);
  }
}
