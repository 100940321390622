<div class="container" class="buscador-container mat-elevation-z3">
    <div class="row align-items-center">
        <div class="col-xl-10">
            <mat-form-field class="input-text">
                <mat-label><span class="first-capitalize">{{'codigo descuento' | translate}}</span></mat-label>
                <input matInput type="text" [(ngModel)]="codigo_descuento_str" class="input-login">
            </mat-form-field>
        </div>
        <div class="col-xl-2">
            <button mat-raised-button color="primary" class="boton-full-width" (click)="submit()">{{'comprobar' | translate}}</button>
        </div>
    </div>
</div>