import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class FondoService {

  private fondo_defecto: string = AppSettings.FONDOS_PATH + '/fondo_web_aetesys.png';
  private _fondo = new BehaviorSubject(<string> null);

  private _fondo_defecto_activo = new BehaviorSubject(<boolean> null);

  fondo = this._fondo.asObservable();
  fondo_defecto_activo = this._fondo_defecto_activo.asObservable();

  constructor() { }

  public setFondo(val: string){
    this._fondo_defecto_activo.next(false);
    this._fondo.next(val);
  }

  public setFondoDefault(){
    this._fondo_defecto_activo.next(true);
    this._fondo.next(this.fondo_defecto);
  }

  public anularFondo(){
    this._fondo_defecto_activo.next(false);
    this._fondo.next(null);
  }
}
