  <mat-toolbar class="nav-bar mat-elevation-z2 bg-sup">
    <mat-toolbar-row>
      <span><img src="{{urlLogo}}" class="logo-nav" alt="SUP"></span>
      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-lg class="nav-list">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <ng-container *ngFor="let item of menuCurso">
            <button *ngIf="item.tipo_link == tipoLink.ROUTER_LINK" [routerLink]="[item.link]" mat-button><span class="first-capitalize"> {{item.nombre}} </span></button>
        </ng-container>
            <button (click)="redireccionarAula()" mat-button><span class="first-capitalize"> {{'acceso aula' | translate}} </span></button>

      </div>
      <div fxShow="true" fxHide.lt-lg class="login-div">
        <button *ngIf="!loggedin" [routerLink]="['/login']" mat-button><mat-icon class="login-icon">person</mat-icon><span class="first-capitalize login-span"> {{'entrar-identificarse' | translate}} </span></button>
        <button *ngIf="loggedin" (click)="logout()" mat-button><mat-icon class="login-icon">person_off</mat-icon><span class="first-capitalize"> {{'cerrar sesion' | translate}} </span></button>
      </div>
      <app-cesta class="cesta"></app-cesta>
       <button mat-icon-button (click)="drawerOpen()" class="burguer-button" fxShow="true" fxHide.gt-md>
        <mat-icon class="burguer-icon">menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>


