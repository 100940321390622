import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { Injectable } from '@angular/core';
import { Curso } from '../classes/entity-data-models/curso';
import { CodigoDescuento } from '../classes/entity-data-models/codigo-descuento';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonFunctions } from '../classes/common-functions';
import { Encriptacion } from '../classes/encriptacion';
import { TranslateService } from '@ngx-translate/core';
// import { SnotifyService } from 'ng-snotify';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './auth/token/token.service';
import { CategoriascursosService } from './categoriascursos.service';
import { CategoriaCurso } from '../classes/entity-data-models/categoria-curso';
import { TipoPago } from '../classes/entity-data-models/tipo-pago';
import {environment} from '../../environments/environment';
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class CestaService {

  private readonly notifier: NotifierService;

  private baseURL: string = AppSettings.API_ENDPOINT;
  private enc: Encriptacion = new Encriptacion();
  private id_empresa: string = null;

  private _cesta: ItemCesta[]         = [];
  private _cesta_observable           = new BehaviorSubject(<ItemCesta[]> []);
  private _codigo_descuento_aplicado  = new BehaviorSubject(<CodigoDescuento> null);
  private _items_erroneos             = new BehaviorSubject(<ItemCesta[]> []);
  private _importe_total              = new BehaviorSubject(<number> 0);
  private _importe_descontado         = new BehaviorSubject(<number> 0);
  private _importe_final              = new BehaviorSubject(<number> 0);

  private _id_forma_pago      = new BehaviorSubject(<number> null);
  public   id_forma_pago      = this._id_forma_pago.asObservable();

  private _cerrar_modal       = new BehaviorSubject(<boolean> false);
  public   cerrar_modal       = this._cerrar_modal.asObservable();

  private _auto_apertura      = new BehaviorSubject(<boolean> false);
  public   autoApertura      = this._auto_apertura.asObservable();

  private categoriaSub        = new Subscription();

  private categoria_seleccionada: CategoriaCurso = null;



  constructor(
    private translate: TranslateService,
    // private sNotify: SnotifyService,
    private http: HttpClient,
    private Token: TokenService,
    private categoriasSrv: CategoriascursosService,
    private notifierService: NotifierService
  ) {
    this.categoriasSrv.categoria_seleccionada.subscribe((cat: CategoriaCurso) => this.categoria_seleccionada = cat);
    this.id_empresa = this.enc.encriptar(AppSettings.id_empresa.toString());
    this.notifier = notifierService;
  }



  public enviarCesta(){
    this.actualizarCodigoAplicado();
    let request = {
      token: this.Token.get(),
      cursos: this._cesta,
      id_empresa: this.id_empresa,
      codigo: this._codigo_descuento_aplicado.value,
      metodopago: this._id_forma_pago.value
    };
      let mapForm = document.createElement("form");
      mapForm.target = 'egs';
      mapForm.method = 'POST'; // or "post" if appropriate
      mapForm.action = 'https://www.formacionegs.com/carrito/operaciones/procesa_pedido.php';

      let mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = "request";
      mapInput.value = JSON.stringify(request);
      mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);


    mapForm.submit();
    return this.http.post('https://www.formacionegs.com/carrito/operaciones/procesa_pedido.php', request);
  }

  public recuperarCesta(){
    let cestaStr = sessionStorage.getItem(AppSettings.CESTA_VAR);
    let cesta = [];
    if(cestaStr != null){
      cesta = JSON.parse(cestaStr);
    }else{
      cesta = [];
    }

    let arrCesta = [];

    cesta.forEach(function(item){
      let curso             : Curso           = new Curso(item.curso);
      let codigo_descuento  : CodigoDescuento = (item.codigo_descuento !== null) ? new CodigoDescuento(item.codigo_descuento) : null;

      let item_cesta: ItemCesta = new ItemCesta(curso, codigo_descuento);

      arrCesta.push(item_cesta);
    });

    this._cesta = arrCesta;

    this._cesta_observable.next(this._cesta);

    this.calcularImportes();
  }

  public insertarItem(curso: Curso, codigo_descuento: CodigoDescuento = null){
    try{
          curso.categoria_diploma = this.categoria_seleccionada?.nombre;

          let item: ItemCesta   = new ItemCesta(curso,codigo_descuento);

          this._cesta.push(item);
          this._cesta_observable.next(null);
          this._cesta_observable.next(this._cesta);

          this.almacenarCesta();

          this.calcularImportes();

          this.translate.get('anadir cesta exito', {curso: curso.nombre}).subscribe((msg:string) => {
            this.notifier.notify('success', msg)
            // this.sNotify.success(msg, AppSettings.sNotifyCommonConf);
          });
          this.actualizarItemsErroneos();

          this.categoriaSub.unsubscribe();

          return true;

    }catch(Error){
      return false;
    }


  }

  public eliminarItem(item: ItemCesta){
    let index: number = -1;

    index = this._cesta.indexOf(item);

    if(index > -1){
      this._cesta.splice(index, 1);
      this._cesta_observable.next(this._cesta);
      this.almacenarCesta();
      this.calcularImportes();
    }
    this.actualizarItemsErroneos();
    this.actualizarCodigoAplicado();

  }

  public get(){
    return this._cesta_observable.asObservable();
  }

  public set(cesta: ItemCesta[]){
    try{
      this._cesta = cesta;
      this._cesta_observable.next(cesta);
      this.almacenarCesta();
      this.actualizarItemsErroneos();
      this.calcularImportes();

      return true;
    }catch(error){
      return false;
    }

  }
//función para aplicar descuento a la cesta entera
  public aplicarDescuentoCesta(descuento: CodigoDescuento ){
    for(let i = 0; i < this._cesta.length; i++){
        this._cesta[i].codigo_descuento = descuento;
    }
    this._cesta_observable.next(null);
    this._cesta_observable.next(this._cesta);
    this.almacenarCesta();
    this.calcularImportes();
    this.actualizarCodigoAplicado();
  }
  //Funcion para aaplicar descuentos a solo cursos especificos
  public aplicarDescuentoCurso(descuento: CodigoDescuento, CursosAplicables ){
    for(let i = 0; i < this._cesta.length; i++){
      for(let u = 0; u < CursosAplicables.length; u++){
        this._cesta[i].codigo_descuento = descuento;
        if(this._cesta[i].curso.idtrcurscat == CursosAplicables[u].idtrcurso){
          // if(CursosAplicables[u].descuento_excl && CursosAplicables[u].tipodescuento_excl){
          //   console.log(CursosAplicables[u].descuento_excl, CursosAplicables[u].tipodescuento_excl, this._cesta[i].codigo_descuento,  "capliclabese")
          //   this._cesta[i].codigo_descuento.cantidad = CursosAplicables[u].descuento_excl
          // }
          //bn
          // this._cesta[i].cursosAplicables.push(this._cesta[i].curso.idtrcurscat)
          this._cesta[i].cursosAplicables.push(CursosAplicables[u])
        }
      }
    }
    this._cesta_observable.next(null);
    this._cesta_observable.next(this._cesta);
    this.almacenarCesta();
    this.calcularImportes();
    this.actualizarCodigoAplicado();
  }

  public vaciarCesta(){
    this._cesta = [];
    this._cesta_observable.next([]);
    this._codigo_descuento_aplicado.next(null);
    this._id_forma_pago.next(null);
    this.almacenarCesta();
    this.actualizarCodigoAplicado();
  }

  public getCodigoAplicado(){
    return this._codigo_descuento_aplicado.asObservable();
  }

  public getImporteTotal(){
    return this._importe_total.asObservable();
  }

  public getImporteDescontado(){
    return this._importe_descontado.asObservable();
  }

  public getImporteFinal(){
    return this._importe_final.asObservable();
  }
//funcion para calcular el importe final (cambiar aqui las condiciones para que no recalcule el precio de todo)
  public calcularImportes(){
    let enc = new Encriptacion();
    let importe_total: number = 0;
    let importe_descontado: number = 0;
    let importe_final: number = 0;

    this._cesta.forEach(function(item: ItemCesta){
       let curso: Curso = item.curso
       let descuento: CodigoDescuento = item.codigo_descuento;
       let precio:number = curso.precio_seleccionado;

       importe_total = importe_total + precio;

       if(descuento != null){
         if(item.codigo_descuento.general==1){
           let cantidad: number = descuento.cantidad;
           let tipoDescuento: number = +enc.desencriptar(descuento.tipo);

           let descontado: number = CommonFunctions.obtenerPrecioDescuento(precio, cantidad, tipoDescuento);

           importe_descontado = importe_descontado + descontado;
          }else if(item.cursosAplicables.length>=1){
                //en caso de que se puedan aplicar varios cursos descomentar for
            //for(let cursoid of item.cursosAplicables){
              //console.log(item.cursosAplicables)
              if(item.cursosAplicables[0].idtrcurso == item.curso.idtrcurscat){
                if(item.cursosAplicables[0].descuento_excl && item.cursosAplicables[0].tipodescuento_excl){
               //   console.log("excl")
                  let cantidad: number = item.cursosAplicables[0].descuento_excl;
                  let tipoDescuento: number = item.cursosAplicables[0].tipodescuento_excl;

                  let descontado: number = CommonFunctions.obtenerPrecioDescuento(precio, cantidad, tipoDescuento);

                  importe_descontado = importe_descontado + descontado;
                }else{
                //  console.log("noexlcl")
                  let cantidad: number = descuento.cantidad;
                  let tipoDescuento: number = +enc.desencriptar(descuento.tipo);

                  let descontado: number = CommonFunctions.obtenerPrecioDescuento(precio, cantidad, tipoDescuento);
                  importe_descontado = importe_descontado + descontado;
                }
              }
            // }
          }

       }

    });

    importe_final = importe_total - importe_descontado;

    this._importe_total.next(importe_total);
    this._importe_descontado.next(importe_descontado);
    this._importe_final.next(importe_final);
  }

  public existeItem(curso: Curso) : boolean{
    let filter_result: ItemCesta[] = [];
    filter_result = this._cesta.filter((item: ItemCesta) => item.curso.idtrcurscat == curso?.idtrcurscat);

    return (filter_result.length > 0);
  }

  public findItemByCurso(curso: Curso) : ItemCesta{
    let filter_result: ItemCesta = null;
    filter_result = this._cesta.find((item: ItemCesta) => item.curso.idtrcurscat == curso?.idtrcurscat);

    return filter_result;
  }

  public cerrarModal(){
    this._cerrar_modal.next(true);
    this._cerrar_modal.next(false);
  }

  public activarAutoApertura(){
    this._auto_apertura.next(true);
  }

  public desactivarAutoApertura(){
    this._auto_apertura.next(false);
  }

  public almacenarCesta(){
    if(this._cesta.length > 0){
      sessionStorage.setItem(AppSettings.CESTA_VAR, JSON.stringify(this._cesta));
    }else{
      sessionStorage.removeItem(AppSettings.CESTA_VAR);
    }
  }

  public actualizarItemsErroneos(){
    let items: ItemCesta[] = this._cesta.filter((item: ItemCesta) =>
    (item.curso.convocatoria_seleccionada == null || item.curso.convocatoria_seleccionada == undefined) ||
    (item.curso.categoria_diploma == null || item.curso.categoria_diploma == undefined || item.curso.categoria_diploma == '')
    );
    this._items_erroneos.next(items);
  }

  public getItemsErroneos(){
    this.actualizarItemsErroneos();
    return this._items_erroneos.asObservable();
  }

  public actualizarCodigoAplicado(){
    let items: ItemCesta[] = this._cesta.filter((ic: ItemCesta) => ic.codigo_descuento != null && ic.codigo_descuento != undefined);
    let codigo_descuento: CodigoDescuento = null;

    if(items.length > 0){
      codigo_descuento = items[0].codigo_descuento;
    }else{
      codigo_descuento = null;
    }

    this._codigo_descuento_aplicado.next(codigo_descuento);

  }

  public setFormaPago(forma_pago: TipoPago){
      this._id_forma_pago.next(forma_pago.id);
  }

}
