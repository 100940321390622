import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { AuthService } from '../auth.service';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private baseUrl = AppSettings.API_ENDPOINT;
  private enc: Encriptacion = new Encriptacion();

  constructor(
    private http: HttpClient,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService
  ) { }

  private _me = new BehaviorSubject(null);
  public me = this._me.asObservable();

  getProfile(data) {
    return this.http.post(this.baseUrl + '/auth/profile/me', data);
  }

  updateMe(){
    let token = this.Token.get();
    let data = {
      token: token,
      admin: false
    }

    this.getProfile(data).subscribe(
      (usr: any) => {
        this._me.next(usr)
      },
      error => this.handleError(error)
    );
  }
  private handleError(error){
    if(error.status == 401){
      this.Auth.changeAuthStatus(false);
      this.Token.remove();
      this.router.navigateByUrl('/login');
    }
  }
}
