import { TiposPagoService } from './services/tipos-pago.service';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriascursosService } from './services/categoriascursos.service';
import { AuthService } from './services/auth/auth.service';
import { TokenService } from './services/auth/token/token.service';
import { NacionalidadesService } from './services/nacionalidades.service';
import { FondoService } from './services/fondo.service';
import { CestaService } from './services/cesta.service';
import { MatDrawer } from '@angular/material/sidenav';
import { DrawerService } from './services/drawer.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuCursosService } from './services/menu-cursos.service';
import { ItemMenuCurso } from './classes/entity-data-models/item-menu-curso';
import { TipoLink } from './enum/tipo-link.enum';
import { AppSettings } from './app-settings';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer; 

  public title: string = 'aetesys';

  public fondo: string = null;
  public fondo_default_activo: boolean = null;
  public loggedin: boolean = false;
  public menuCurso: ItemMenuCurso[] = [];
  public tipoLink = TipoLink;


  private translateSub: Subscription = null;
  // private notifier: NotifierService;
  constructor(
    private translate: TranslateService,
    private categoriasServ: CategoriascursosService,
    private fondoServ: FondoService,
    private Auth: AuthService,
    private Token: TokenService,
    private nacionalidadesServ: NacionalidadesService,
    private cestaSrv: CestaService,
    private drawerSrv: DrawerService,
    private router: Router,
    private menuSrv: MenuCursosService,
    private tipos_pago_service: TiposPagoService,
    private notifier: NotifierService
  ){
    this.notifier = notifier;
    this.translate.setDefaultLang('es')
  }
  ngOnDestroy(): void {
    if(this.translateSub != null){
      this.translateSub.unsubscribe();
    }
  }

  
  ngAfterViewInit() {
    setTimeout(()=>{
      this.fondoServ.fondo.subscribe((res: string) => {
        this.fondo = (res != null) ? res : '';
      });
      this.fondoServ.fondo_defecto_activo.subscribe((val:boolean) => this.fondo_default_activo = val);
    }, 1000);
  }
    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

  ngOnInit(): void {

      this.Token.checkToken().subscribe(
        (val:boolean) => {
        this.Auth.changeAuthStatus(val);
        },
        error => {
           this.Auth.changeAuthStatus(false); 
        }
      );

      this.Auth.authStatus.subscribe(val => this.loggedin = val);

      this.categoriasServ.updateCategorias();
      this.categoriasServ.updateCategoriasSeleccionables();
      this.nacionalidadesServ.updateNacionalidades();
      this.cestaSrv.recuperarCesta();

       this.drawerSrv.abierto.subscribe(val => {
         if(val){
           this.abrirDrawer();
         }
       });

       //Configuro item inicial de menu
       this.translateSub = this.translate.get('categorias').subscribe((categoria_nombre: string) => {
        let item_menu: ItemMenuCurso = new ItemMenuCurso(categoria_nombre, '/', TipoLink.ROUTER_LINK);
        this.menuSrv.delete();
        this.menuSrv.push(item_menu);
      });

      this.menuSrv.menu.subscribe((menu: ItemMenuCurso[]) => {
        setTimeout(()=>this.menuCurso = menu);
      });

      this.tipos_pago_service.inicializarTiposPago();
  }

  abrirDrawer(){
    this.drawer.open();
  }

  cerrarDrawer(){
    this.drawer.close();
  }

  logout() {
    this.Auth.logout().subscribe(
      data => this.handleLogout()
    );
  }

  redireccionarAula(){
    this.cerrarDrawer();
    window.open(AppSettings.LOGIN_GESTION_FORMATIVA, '_blank');
  }

  private handleLogout() {
    this.cerrarDrawer();
    this.Auth.changeAuthStatus(false);
    this.Token.remove();
    this.router.navigateByUrl('/login');
  }



}
