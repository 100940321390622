import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaitingSpinnerService {

  private _activado       = new BehaviorSubject(<boolean> false);
  private _titulo         = new BehaviorSubject(<string> '');
  private _subtitulo      = new BehaviorSubject(<string> '');

  public activado         = this._activado.asObservable();
  public titulo           = this._titulo.asObservable();
  public subtitulo        = this._subtitulo.asObservable();


  constructor() { }

  public activar(){
    this._activado.next(true);
  }
  public desactivar(){
    this._activado.next(false);
  }
  public setTitulo(val: string){
    this._titulo.next(val);
  }
  public setSubTitulo(val: string){
    this._subtitulo.next(val);
  }

  
  
}
