import { DrawerService } from 'src/app/services/drawer.service';
import { AppSettings } from 'src/app/app-settings';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token/token.service';
import { Router } from '@angular/router';
import { ItemMenuCurso } from 'src/app/classes/entity-data-models/item-menu-curso';
import { MenuCursosService } from 'src/app/services/menu-cursos.service';
import { TipoLink } from 'src/app/enum/tipo-link.enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  public urlLogo: string = AppSettings.LOGO_NAV;
  public loggedin: boolean = null;
  public menuCurso: ItemMenuCurso[] = []; 
  public tipoLink = TipoLink;


  constructor(
    private Auth: AuthService,
    private Token: TokenService,
    private router: Router,
    private Drawer: DrawerService,
    private menuCursoSrv: MenuCursosService
  ) { }

  ngOnInit(): void {
    this.Auth.authStatus.subscribe(val => this.loggedin = val);
    this.menuCursoSrv.menu.subscribe((val:ItemMenuCurso[]) => {
      this.menuCurso = val;
    });
  }

  logout() {
    this.Auth.logout().subscribe(
      data => this.handleLogout()
    );
  }

  private handleLogout() {
    this.Auth.changeAuthStatus(false);
    this.Token.remove();
    this.router.navigateByUrl('/login');
  }

  drawerOpen(){
    this.Drawer.abrir();
  }

  redireccionarAula(){
    window.open(AppSettings.LOGIN_GESTION_FORMATIVA, '_blank');
  }
}
