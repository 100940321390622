<div class="boton-anadir">
    <button mat-fab color="add-cart" (click)="anadirCesta()" aria-label="'cesta compra' | translate" [disabled]="!tiene_convocatorias || cargando">
        <mat-icon *ngIf="cargando && tiene_convocatorias">watch_later</mat-icon>
        <mat-icon *ngIf="!cargando && tiene_convocatorias">add_shopping_cart</mat-icon>
        <mat-icon *ngIf="!tiene_convocatorias">lock</mat-icon>
    </button>
</div>
<div class="label-anadir mat-elevation-z2" *ngIf="tiene_convocatorias">
    <span class="first-capitalize" *ngIf="tiene_convocatorias">{{'anadir cesta' | translate}}</span>
</div>
<div class="label-no-convocatoria mat-elevation-z2" *ngIf="!tiene_convocatorias">
    <span class="first-capitalize">{{'no convocatorias' | translate}}</span>
</div>


