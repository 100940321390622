// import { SnotifyPosition, SnotifyToastConfig } from 'ng-snotify';
import { NotifierModule } from 'angular-notifier';
import { environment } from 'src/environments/environment';

export class AppSettings {
    public static VERSION: string                 = "1.1";
    public static API_ENDPOINT: string            = environment.API_ENDPOINT;
    public static id_empresa: number              = environment.id_empresa;
    public static URL_GESTIONFORMATIVA: string    = environment.url_gestionformativa;
    public static URL_EMPRESA:string              = environment.url_empresa;

    public static LOGIN_GESTION_FORMATIVA: string = AppSettings.URL_GESTIONFORMATIVA + '/accesoalumnos.php?mp=' + AppSettings.id_empresa;

    public static LOGIN_REDIRECTION_ROUTE:string    = '';
    public static LOGIN_ROUTE:string                = '/login';
    public static SPLIT_CHAR:string                 = '_';

    public static ASSETS_PATH:string        = '/assets';
    public static IMG_PATH:string           =  AppSettings.ASSETS_PATH + '/img';
    public static GF_IMG_PATH:string        =  AppSettings.URL_GESTIONFORMATIVA + '/img';
    public static GF_ACRED_IMG_PATH:string  =  AppSettings.GF_IMG_PATH + '/acreditaciones';
    public static FONDOS_PATH:string        =  AppSettings.IMG_PATH + '/fondos';
    public static LOGO_PATH:string          =  AppSettings.IMG_PATH + '/logos';
    public static LOGO_BAC_PATH:string      =  AppSettings.IMG_PATH + '/logo-bac';
    public static LOGO_BAC:string           =  AppSettings.LOGO_BAC_PATH + '/logo_bac_cab.png';
    public static LOGO_NAV:string           =  AppSettings.LOGO_PATH + '/logo_SUP.png';
    public static LOGO_XXL:string           =  AppSettings.LOGO_PATH + '/logo_SUP.png';

    public static CESTA_VAR:string = 'cesta';

    constructor(){
    }

    // public static sNotifyCommonConf = {
    //     timeout: 2500,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     buttons: null
    //   }


    // public static sNotifyRightBottomConf = {
    //     timeout: 2500,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     buttons: null
    //   }


    // public static getConfirmConf(botones){
    //     // let conf: SnotifyToastConfig    = {};
    //     conf.timeout                    = 0;
    //     // conf.showProgressBar            = AppSettings.sNotifyCommonConf.showProgressBar;
    //     // conf.closeOnClick               = AppSettings.sNotifyCommonConf.closeOnClick;
    //     // conf.pauseOnHover               = AppSettings.sNotifyCommonConf.pauseOnHover;
    //     // conf.position                   = SnotifyPosition.centerCenter;
    //     conf.buttons                    = botones;
    //     conf.backdrop                   = 0.5;
    //
    //     return conf;
    // }
    public static getInfoConf(botones , html:string){

        return true;
    }


}
