<div class="container" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <div class="col-md-12 card-info mat-elevation-z8">

            <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;background:white;'><strong><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>CONDICIONES GENERALES DE CONTRATACI&Oacute;N</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Estas condiciones generales de la contrataci&oacute;n hacen referencia al portal http://www.aetesys.gesti&oacute;nformativa.es y sus subdominios, en adelante referido como el &ldquo;Portal&rdquo;, as&iacute; como a sus Apps para dispositivos m&oacute;viles, smartTVs y otros dispositivos, en adelante referidos como sus &ldquo;Apps&rdquo;.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>De acuerdo al Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios as&iacute; como en la modificaci&oacute;n que dicha normativa sufre por la Ley 3/2014, de 27 de marzo (Ley de Consumidores y Usuarios), en el Real Decreto 1906/1999, de 17 de diciembre, por el que se regula la contrataci&oacute;n telef&oacute;nica o electr&oacute;nica con condiciones generales en desarrollo del art&iacute;culo 5.3 de la Ley 7/1998, de 13 de abril, de condiciones generales de la contrataci&oacute;n (BOE 313, de 31 de diciembre de 1999) y dem&aacute;s normativa legal espa&ntilde;ola aplicable, estas son las condiciones de contrataci&oacute;n para los usuarios en el Portal y sus Apps.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Activos de aprendizaje</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Desde el portal y sus Apps el Usuario podr&aacute; adquirir Activos de Aprendizaje que podr&aacute;n ser de diferentes tipos: Cursos, Documentos, Sitios web, Intranets, V&iacute;deos, y cualquier otro que se ponga a su disposici&oacute;n. Los activos de Aprendizaje podr&aacute;n ser gratuitos o requerir de un pago individualizado seg&uacute;n cada caso.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Licencia de uso y validez</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Cuando el Usuario dispone de un acceso gratuito o adquiere un Activo de Aprendizaje o cualquier otro de los servicios ofrecidos desde el Portal y sus Apps, adquiere &uacute;nicamente la licencia de uso para acceder y realizar el Activo de Aprendizaje o disfrutar el servicio, con las condiciones inexcusables de acceder y realizarlo en el Portal y/o sus Apps, con el l&iacute;mite temporal establecido en cada caso, una vez vencido el l&iacute;mite temporal la licencia deja de ser vigente para el Usuario. La duraci&oacute;n est&aacute;ndar de contrataci&oacute;n es de un a&ntilde;o. La licencia no permite en ning&uacute;n caso la descarga, copia, reproducci&oacute;n, distribuci&oacute;n y/o el almacenamiento del mismo por el Usuario.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>La licencia es personal e intransferible no pudiendo ser facilitado el acceso a terceros por parte del Usuario.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Capacidad jur&iacute;dica y requisitos</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>El Usuario ha de ser mayor de edad o tener capacidad jur&iacute;dica suficiente para contratar de acuerdo a la legislaci&oacute;n espa&ntilde;ola.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Para poder realizar la contrataci&oacute;n el Usuario habr&aacute; debido de registrarse como Usuario facilitando los datos requeridos de forma cierta, veraz y completa.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Precios y pago</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>En el caso de que los Activos de Aprendizaje requieran de un pago el usuario dispondr&aacute; de medios de pago seguro online. El Usuario deber&aacute; facilitar la informaci&oacute;n de pago necesaria para completar el proceso. El pago de los cursos de formaci&oacute;n se efectuar&aacute; a la entidad formativa colaboradora INSTITUTO BAC FORMACION S.L (NIF N&ordm; B-O2927556) y a tal efecto a esta se le ceder&aacute;n los datos de los alumnos/as por parte de la ASOCIACI&Oacute;N ESPA&Ntilde;OLA DE TECNICOS DE ENFERMERIA, EMERGENCIAS, SANITARIOS Y SOCIOSANITARIOS (AETESYS)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>ASOCIACI&Oacute;N ESPA&Ntilde;OLA DE TECNICOS DE ENFERMERIA, EMERGENCIAS, SANITARIOS Y SOCIOSANITARIOS (AETESYS) no accede ni conoce dicha informaci&oacute;n de pago.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Los precios de los Activos de Aprendizaje y/o Servicios del Portal y sus Apps incluyen, de ser aplicable, el Impuesto sobre el Valor A&ntilde;adido, as&iacute; como los impuestos aplicables de acuerdo a la normativa espa&ntilde;ola vigente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Una vez realizado satisfactoriamente el pago el Usuario dispondr&aacute; del acceso al Activo de Aprendizaje o Servicio, recibiendo v&iacute;a email la confirmaci&oacute;n de la misma. Si el Usuario requiere de una factura en papel deber&aacute; solicitarlo por email en la direcci&oacute;n info@bacformacion.com indicando nombre de usuario, nombre y apellidos, fecha de la compra y concepto de la misma as&iacute; como direcci&oacute;n postal a la que remitirlo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Desistimiento</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Una vez iniciado el Activo de Aprendizaje o el Servicio adquirido el usuario pierde su derecho a Desistimiento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Para ejercer su Derecho de Desistimiento el Usuario deber&aacute; comunicar su solicitud por email en la direcci&oacute;n info@bacformacion.com indicando nombre de usuario, nombre y apellidos, fecha de la compra y concepto de la misma.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>El usuario tiene derecho a desistir de la contrataci&oacute;n realizada, en los t&eacute;rminos previstos en la Ley de Consumidores y Usuarios durante un per&iacute;odo de 14 d&iacute;as naturales a contar desde la formalizaci&oacute;n de la compra del Activo de Aprendizaje o Servicio mediante el pago del mismo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>En caso de desistimiento INSTITUTO BAC FORMACION S.L. devolver&aacute; al usuario el importe total del curso en un plazo m&aacute;ximo de 30 d&iacute;as naturales a partir de la fecha de comunicaci&oacute;n por el usuario de dicho desistimiento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:19.5pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:22px;font-family:"Arial",sans-serif;color:black;'>Aceptaci&oacute;n</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:15.75pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:black;'>Tanto el acceso a el Portal y/o sus Apps como la compra de cualquier Activo de Aprendizaje o Servicio supone la total e incondicional aceptaci&oacute;n por el Usuario del Aviso legal, Condiciones de Contrataci&oacute;n, Pol&iacute;tica de privacidad y Pol&iacute;tica de cookies, quedando vinculado contractualmente con AETESYS con los mismo efectos que su firma.</span></p>

        </div>
    </div>
</div>