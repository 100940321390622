<div class="container containerbotspace">
  <div class="row justify-content-center" >
    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3600">
        <img src="/assets/img/categorias/egs-cuerpo-nacional-de-policia3536_def.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Cuerpos y Fuerzas de Seguridad</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3607">
        <img src="/assets/img/categorias/alemania1.png" class="card-img-top cardhome" alt="...">
        <div class="card-body ">
          <p class="card-text text-center">
            Alemán</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3608">
        <img src="/assets/img/categorias/frances.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Francés</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3609">
        <img src="/assets/img/categorias/ingles.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Inglés</p>
        </div>
      </a>
    </div>
    </div>
  <div class="row" >
    <!-- Fila 2-->
    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3612">
        <img src="/assets/img/categorias/Cursos%20transversales.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">Cursos transversales</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3613">
        <img src="/assets/img/categorias/idiomas.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Educación</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3618">
        <img src="/assets/img/categorias/medico.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Técnico en cuidados auxiliares de enfermería</p>
        </div>
      </a>
    </div>


    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3615">
        <img src="/assets/img/categorias/ofimática.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Ofimática</p>
        </div>
      </a>
    </div>
  </div>
  <div class="row" >
    <!-- Fila 3 -->

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3616">
        <img src="/assets/img/categorias/enfermeria1095_def.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Enfermero</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3617">
        <img src="/assets/img/categorias/medico.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Médico</p>
        </div>
      </a>
    </div>

    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3614">
        <img src="/assets/img/categorias/justicia.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">
            Justicia</p>
        </div>
      </a>
    </div>


    <div class="col-md-3 col-card-categoria  text-center" >
      <a href="/cursos/3619">
        <img src="/assets/img/categorias/farmacia1081_def.png" class="card-img-top cardhome" alt="...">
        <div class="card-body">
          <p class="card-text text-center">Técnico en farmacia</p>
        </div>
      </a>
    </div>

  </div>
</div>
