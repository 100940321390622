import { PasswordValidator } from 'src/app/classes/validators/password-validator';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StepperService } from 'src/app/services/formulario_registro/stepper.service';

@Component({
  selector: 'app-paso2',
  templateUrl: './paso2.component.html',
  styleUrls: ['./paso2.component.css']
})
export class Paso2Component implements OnInit {

  public firstSubmit: boolean = false;
  public form: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('',[
      Validators.required
    ]),
    password_confirm: new UntypedFormControl('')
  },PasswordValidator.passwordRepeat);

  constructor(
    private stepperSrv: StepperService,
  ){

  }

  enviar(){
    this.firstSubmit = true;
    if(this.form.status === 'VALID'){
      this.stepperSrv.updateContrasena(this.form);
      this.stepperSrv.irPrimerIndiceConErrores();
    }/**/
  
  }

  ngOnInit(): void {
  }

}
