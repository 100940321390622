import { ICodigoDescuento } from 'src/app/interfaces/i-codigo-descuento';
export class CodigoDescuento {
    public id:string = null;
    public idtrcurso:number = null;
    public cantidad:number = null;
    public codigo:string = null;
    public descripcion:string = null;
    public fechafin:string = null;
    public fechainicio:string = null;
    public general:number = null;
    public idcategoria:string = null;
    public idempresa:string = null;
    public modalidadistancia:number = null;
    public modalidadonline:number = null;
    public modalidadotros:number = null;
    public newformat:string = null;
    public nombre:string = null;
    public solapar:number = null;
    public tipo:string = null;
    public descuento_excl:number = null;
    public tipodescuento_excl:number = null;
    constructor(codigo_descuento: ICodigoDescuento = null){

        if(codigo_descuento != null){

            this.id                 = codigo_descuento.id;
            this.idtrcurso          = codigo_descuento.idtrcurso;
            this.cantidad           = codigo_descuento.cantidad;
            this.codigo             = codigo_descuento.codigo;
            this.descripcion        = codigo_descuento.descripcion;
            this.fechafin           = codigo_descuento.fechafin;
            this.fechainicio        = codigo_descuento.fechainicio;
            this.general            = codigo_descuento.general;
            this.idcategoria        = codigo_descuento.idcategoria;
            this.idempresa          = codigo_descuento.idempresa;
            this.modalidadistancia  = codigo_descuento.modalidadistancia;
            this.modalidadonline    = codigo_descuento.modalidadonline;
            this.modalidadotros     = codigo_descuento.modalidadotros;
            this.newformat          = codigo_descuento.newformat;
            this.nombre             = codigo_descuento.nombre;
            this.solapar            = codigo_descuento.solapar;
            this.tipo               = codigo_descuento.tipo;
            this.descuento_excl = null;
            this.tipodescuento_excl= null;
        }
    
    }

}
