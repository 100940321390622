// import { Encriptacion } from './../classes/encriptacion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { Encriptacion } from '../classes/encriptacion';

@Injectable({
  providedIn: 'root'
})
export class ConvocatoriasService {
  private baseURL: string = AppSettings.API_ENDPOINT;
  
  private enc = new Encriptacion();

  constructor(
    private http: HttpClient
  ) { }

  getByAcreditacion(idacreditacion: string){
    let id_acred =  this.enc.encriptar(idacreditacion);
    return this.http.get(this.baseURL + '/convocatorias/find/by/acreditacion/' + id_acred);
  }

}
