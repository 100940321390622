import { Component, Input, OnInit } from '@angular/core';
import { CodigoDescuento } from 'src/app/classes/entity-data-models/codigo-descuento';
import { Curso } from 'src/app/classes/entity-data-models/curso';
import { ResponseApi } from 'src/app/classes/response-api';
import { CestaService } from 'src/app/services/cesta.service';
import { ConvocatoriasService } from 'src/app/services/convocatorias.service';

@Component({
  selector: 'app-boton-anadir-cesta',
  templateUrl: './boton-anadir-cesta.component.html',
  styleUrls: ['./boton-anadir-cesta.component.css']
})
export class BotonAnadirCestaComponent implements OnInit {
  @Input() curso: Curso = null;
  @Input() codigo_descuento: CodigoDescuento = null;

  public cargando: boolean = false;
  public tiene_convocatorias: boolean = true;

  constructor(
    private Cesta: CestaService,
    private convocatoriasSrv: ConvocatoriasService
  ) { }

  ngOnInit(): void {
  }

  anadirCesta(){

    if(this.curso !== null){
      this.cargando = true;
      this.convocatoriasSrv.getByAcreditacion(this.curso.idacreditacion).subscribe(
        (resApi: ResponseApi) => {
          this.cargando = false;
          if(resApi != null){
            this.curso.convocatorias = resApi.data.convocatorias;
            this.Cesta.insertarItem(this.curso, this.codigo_descuento);
            this.tiene_convocatorias = true;
          }else{
            this.tiene_convocatorias = false;
          }
        }
      );
      
    }
    
  }

}
